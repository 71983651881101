import React, {Suspense} from 'react'
import {
    Router,
    Switch,
    Route
} from 'react-router-dom'
import history from '../history'
import PrivateRoute from './PrivateRoute'
import useGeoLocation from "react-ipgeolocation";
import {setCurrency} from '../actions/currency'
import {connect} from 'react-redux'

//page components
import Home from './pages/Home'
import About from './pages/About'
import Rugs from './pages/Rugs'
import Colours from './pages/Colours'
import Gallery from './pages/Gallery'
import StudioSamples from './pages/StudioSamples'
import Contact from './pages/Contact'
import Blog from './pages/Blog'
import BlogArticle from './pages/BlogArticle'
import ContactThankyou from './pages/ContactThankyou'
import NewsletterSubscription from './pages/NewsletterSubscription'
import NewsletterSubscriptionThankyou from './pages/NewsletterSubscriptionThankyou'
import PageNotFound from './pages/PageNotFound'
import SignIn from './pages/SignIn'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import Navigate from './Redirect'
import ShopCategoryPage from './shop/ShopCategoryPage'
import ShopProductPage from './shop/ShopProductPage'
import ShopPage from './shop/ShopPage'
import CartPage from './cart/CartPage'
import CheckoutPage from './checkout/CheckoutPage'
import CheckoutPaymentPage from './checkout/CheckoutPaymentPage'
import OrderConfirmationPage from './checkout/OrderConfirmationPage'
import LoginRegisterPage from './account/LoginRegisterPage'
import AccountDashboard from './account/AccountDashboard'
import AccountOrders from './account/AccountOrders'
import AccountOrder from './account/AccountOrder'
import AccountBilling from './account/AccountBilling'
import AccountShipping from './account/AccountShipping'
import AccountProfile from './account/AccountProfile'
import ForgotPassword from './pages/ForgotPassword'
import ForgotPasswordOtp from './pages/ForgotPasswordOtp'
import ForgotPasswordReset from './pages/ForgotPasswordReset'
import CheckoutPaymentStatusPage from './checkout/CheckoutPaymentStatusPage'

//dashboard
const DashboardHome = React.lazy(() => import('./pages/DashboardHome'))
const DashboardUsers = React.lazy(() => import('./pages/DashboardUsers'))
const DashboardAccount = React.lazy(() => import('./pages/DashboardAccount'))
const DashboardUsersCreate = React.lazy(() => import('./pages/DashboardUsersCreate'))
const DashboardUsersEdit = React.lazy(() => import('./pages/DashboardUsersEdit'))
const DashboardUsersBin = React.lazy(() => import('./pages/DashboardUsersBin'))
const DashboardProducts = React.lazy(() => import('./pages/DashboardProducts'))
const DashboardProductsCreate = React.lazy(() => import('./pages/DashboardProductsCreate'))
const DashboardProductsEdit = React.lazy(() => import('./pages/DashboardProductsEdit'))
const DashboardProductsBin = React.lazy(() => import('./pages/DashboardProductsBin'))
const DashboardProductGallery = React.lazy(() => import('./pages/DashboardProductGallery'))
const DashboardProductGalleryAddNew = React.lazy(() => import('./pages/DashboardProductGalleryAddNew'))
const DashboardProductGalleryEditImage = React.lazy(() => import('./pages/DashboardProductGalleryEditImage'))
const DashboardProductGalleryBin = React.lazy(() => import('./pages/DashboardProductGalleryBin'))
const DashboardPostsCreate = React.lazy(() => import('./pages/DashboardPostsCreate'))
const DashboardPostsEdit = React.lazy(() => import('./pages/DashboardPostsEdit'))
const DashboardPosts = React.lazy(() => import('./pages/DashboardPosts'))
const DashboardPostsBin = React.lazy(() => import('./pages/DashboardPostsBin'))
const DashboardMedia = React.lazy(() => import('./pages/DashboardMedia'))
const DashboardGalleries = React.lazy(() => import('./pages/DashboardGalleries'))
const DashboardGalleryImages = React.lazy(() => import('./pages/DashboardGalleryImages'))
const DashboardGalleryImageAddNew = React.lazy(() => import('./pages/DashboardGalleryImageAddNew'))
const DashboardGalleryImageEdit = React.lazy(() => import('./pages/DashboardGalleryImageEdit'))
const DashboardTeamCreate = React.lazy(() => import('./pages/DashboardTeamCreate'))
const DashboardTeamEdit = React.lazy(() => import('./pages/DashboardTeamEdit'))
const DashboardTeam = React.lazy(() => import('./pages/DashboardTeam'))
const DashboardTeamBin = React.lazy(() => import('./pages/DashboardTeamBin'))

//ecommerce dashboard
const DashboardShops = React.lazy(() => import('./pages/shop/DashboardShops'))
const DashboardShopsCreate = React.lazy(() => import('./pages/shop/DashboardShopsCreate'))
const DashboardShopsEdit = React.lazy(() => import('./pages/shop/DashboardShopsEdit'))
const DashboardShopsBin = React.lazy(() => import('./pages/shop/DashboardShopsBin'))
const DashboardShopGallery = React.lazy(() => import('./pages/shop/DashboardShopGallery'))
const DashboardShopGalleryAddNew = React.lazy(() => import('./pages/shop/DashboardShopGalleryAddNew'))
const DashboardShopGalleryEditImage = React.lazy(() => import('./pages/shop/DashboardShopGalleryEditImage'))
const DashboardShopGalleryBin = React.lazy(() => import('./pages/shop/DashboardShopGalleryBin'))
const DashboardCategories = React.lazy(() => import('./pages/categories/DashboardCategories'))
const DashboardCategoriesCreate = React.lazy(() => import('./pages/categories/DashboardCategoriesCreate'))
const DashboardCategoriesEdit = React.lazy(() => import('./pages/categories/DashboardCategoriesEdit'))
const DashboardCategoriesBin = React.lazy(() => import('./pages/categories/DashboardCategoriesBin'))
const DashboardCoupons = React.lazy(() => import('./pages/coupons/DashboardCoupons'))
const DashboardCouponsCreate = React.lazy(() => import('./pages/coupons/DashboardCouponsCreate'))
const DashboardCouponsEdit = React.lazy(() => import('./pages/coupons/DashboardCouponsEdit'))
const DashboardCouponsBin = React.lazy(() => import('./pages/coupons/DashboardCouponsBin'))
const DashboardOrders = React.lazy(() => import('./pages/shop/DashboardOrders'))
const DashboardOrder = React.lazy(() => import('./pages/shop/DashboardOrder'))

const App = props => {

    // const location = useGeoLocation();
    // if(location){
    //     switch (location.country) {
    //         case 'ZA':
    //             props.setCurrency('ZAR')
    //             break;
    //         case 'US':
    //             props.setCurrency('USD')
    //             break;
    //         default:
    //             break;
    //     }
    // }
    
    return(
        <Router history={history}>
            {/* <ScrollToTop /> */}
            <Switch>

                {/* public */}
                <Route exact path="/" component={Home} />
                <Route exact path="/cotton-rugs" component={Navigate} />
                <Route exact path="/indoor-outdoor" component={Navigate} />
                <Route exact path="/about" component={About} />
                <Route exact path="/rugs/:slug" component={Rugs} />
                <Route exact path="/colours-and-patterns" component={Colours} />
                <Route exact path="/gallery-3" component={Gallery} />
                <Route exact path="/gallery" component={Gallery} />
                <Route exact path="/studio-samples" component={StudioSamples} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/contact/thankyou" component={ContactThankyou} />
                <Route exact path="/newsletter-subscription" component={NewsletterSubscription} />
                <Route exact path="/newsletter-subscription/thankyou" component={NewsletterSubscriptionThankyou} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/blog/:slug" component={BlogArticle} />
                <Route exact path="/login" component={SignIn} />  
                <Route exact path="/admin" component={SignIn} />
                <Route exact path="/signin" component={SignIn} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />  
                <Route exact path="/terms-and-conditions" component={TermsAndConditions} />

                {/* shop  */}
                <Route exact path="/shop" component={ShopPage} />
                <Route exact path="/shop/cushions" component={ShopPage} /> 
                <Route exact path="/shop/:categorySlug" component={ShopCategoryPage} /> 
                <Route exact path="/shop/:categorySlug/:slug" component={ShopProductPage} /> 
                <Route exact path="/cart" component={CartPage} /> 
                <Route exact path="/checkout" component={CheckoutPage} />
                <Route exact path="/checkout/payment" component={CheckoutPaymentPage} />
                <Route exact path="/checkout/payment/status" component={CheckoutPaymentStatusPage} />
                <Route exact path="/checkout/complete" component={OrderConfirmationPage} />
                

                {/* account */}
                <Route exact path="/my-account" component={LoginRegisterPage} />
                <Route exact path="/my-account/dashboard" component={AccountDashboard} />
                <Route exact path="/my-account/orders" component={AccountOrders} />
                <Route exact path="/my-account/orders/:orderId" component={AccountOrder} />
                <Route exact path="/my-account/billing" component={AccountBilling} />
                <Route exact path="/my-account/shipping" component={AccountShipping} />
                <Route exact path="/my-account/profile" component={AccountProfile} />

                {/* password forgot */}
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/forgot-password/otp" component={ForgotPasswordOtp} />
                <Route exact path="/forgot-password/reset" component={ForgotPasswordReset} />

                {/* admin */}
                <Suspense fallback={<div></div>}>
                    <PrivateRoute path="/dashboard/products/:id/gallery/:image_id/edit" component={DashboardProductGalleryEditImage} />
                    <PrivateRoute path="/dashboard/shop/:id/gallery/:image_id/edit" component={DashboardShopGalleryEditImage} />
                    <PrivateRoute path="/dashboard/galleries/:id/images/:image_id/edit" component={DashboardGalleryImageEdit} />
                    <PrivateRoute path="/dashboard/products/:id/gallery/add-new" component={DashboardProductGalleryAddNew} />
                    <PrivateRoute path="/dashboard/shop/:id/gallery/add-new" component={DashboardShopGalleryAddNew} />
                    <PrivateRoute path="/dashboard/galleries/:id/images/add-new" component={DashboardGalleryImageAddNew} />
                    <PrivateRoute path="/dashboard/products/:id/gallery/bin" component={DashboardProductGalleryBin} />
                    <PrivateRoute path="/dashboard/shop/:id/gallery/bin" component={DashboardShopGalleryBin} />
                    <PrivateRoute path="/dashboard/products/:id/gallery" component={DashboardProductGallery} />
                    <PrivateRoute path="/dashboard/shop/:id/gallery" component={DashboardShopGallery} />
                    <PrivateRoute path="/dashboard/galleries/:id/images" component={DashboardGalleryImages} />
                    <PrivateRoute path="/dashboard/users/:id/edit" component={DashboardUsersEdit} /> 
                    <PrivateRoute path="/dashboard/products/:id/edit" component={DashboardProductsEdit} />
                    <PrivateRoute path="/dashboard/coupons/:id/edit" component={DashboardCouponsEdit} />
                    <PrivateRoute path="/dashboard/categories/:id/edit" component={DashboardCategoriesEdit} />
                    <PrivateRoute path="/dashboard/shop/:id/edit" component={DashboardShopsEdit} />
                    <PrivateRoute path="/dashboard/posts/:id/edit" component={DashboardPostsEdit} />
                    <PrivateRoute path="/dashboard/team/:id/edit" component={DashboardTeamEdit} />
                    <PrivateRoute path="/dashboard/users/create" component={DashboardUsersCreate} /> 
                    <PrivateRoute path="/dashboard/products/create" component={DashboardProductsCreate} />
                    <PrivateRoute path="/dashboard/coupons/create" component={DashboardCouponsCreate} />
                    <PrivateRoute path="/dashboard/categories/create" component={DashboardCategoriesCreate} />
                    <PrivateRoute path="/dashboard/shop/create" component={DashboardShopsCreate} />   
                    <PrivateRoute path="/dashboard/posts/create" component={DashboardPostsCreate} />
                    <PrivateRoute path="/dashboard/team/create" component={DashboardTeamCreate} />  
                    <PrivateRoute path="/dashboard/users/bin" component={DashboardUsersBin} />  
                    <PrivateRoute path="/dashboard/products/bin" component={DashboardProductsBin} />
                    <PrivateRoute path="/dashboard/coupons/bin" component={DashboardCouponsBin} />
                    <PrivateRoute path="/dashboard/categories/bin" component={DashboardCategoriesBin} />
                    <PrivateRoute path="/dashboard/shop/bin" component={DashboardShopsBin} />  
                    <PrivateRoute path="/dashboard/posts/bin" component={DashboardPostsBin} /> 
                    <PrivateRoute path="/dashboard/team/bin" component={DashboardTeamBin} />        
                    <PrivateRoute path="/dashboard/account" component={DashboardAccount} />
                    <PrivateRoute path="/dashboard/users" component={DashboardUsers} />
                    <PrivateRoute path="/dashboard/products" component={DashboardProducts} />
                    <PrivateRoute path="/dashboard/orders" component={DashboardOrders} />
                    <PrivateRoute path="/dashboard/orders/:orderId" component={DashboardOrder} />
                    <PrivateRoute path="/dashboard/coupons" component={DashboardCoupons} />
                    <PrivateRoute path="/dashboard/categories" component={DashboardCategories} />
                    <PrivateRoute path="/dashboard/shop" component={DashboardShops} />     
                    <PrivateRoute path="/dashboard/posts" component={DashboardPosts} /> 
                    <PrivateRoute path="/dashboard/team" component={DashboardTeam} />
                    <PrivateRoute path="/dashboard/galleries" component={DashboardGalleries} />
                    <PrivateRoute path="/dashboard/media" component={DashboardMedia} />
                    <PrivateRoute path="/dashboard/" component={DashboardHome} /> 
                </Suspense>
                            
                <Route exact component={PageNotFound} />
            </Switch>
        </Router>
    )
}

export default connect(null,{
    setCurrency
})(App)