import React from 'react'
import {Redirect} from 'react-router-dom'

class ShopPage extends React.Component{

    render(){
      return <Redirect to="/shop/cushions-throws" />
    }
}

export default ShopPage