import React, {Suspense} from 'react'
import Layout from '../layout/Layout'
import TemplateOneSection from '../ui/TemplateOneSection'
import ThemeHeading from '../ui/ThemeHeading'
import TemplateImageSection from '../ui/TemplateImageSection'
import ContactForm from '../forms/ContactForm'
import {connect} from 'react-redux'
import {
    sendContactEmail,
    resetSendContactEmail
} from '../../actions'
import history from '../../history'
import NewsletterSubscription from '../ui/NewsletterSubscription'
import ScrolledIntoView from '../../ScrolledIntoView'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'

//images
import img from '../../assets/img/contact-us@2x.jpg'
// import img from '../../assets/img/about-us-03@2x.jpg'
// import imghover from '../../assets/img/about-us-02@2x.jpg'
import clairBrown from '../../assets/img/contact-claire@2x.jpg'

import FlocklerEmbed from '@flockler/react-flockler-embed';

class Contact extends React.Component{

    componentWillUnmount(){
        this.props.resetSendContactEmail()
    }

    componentDidUpdate(){
        if(this.props.sendState.errors){
            setTimeout(() => {
                this.props.resetSendContactEmail()
            }, 7000)
        }
    }

    onSubmitContact = formValues => {
        this.props.sendContactEmail(formValues)
    }

    render(){

        this.props.sendState.success && history.push('/contact/thankyou')

        return(
            <>
            <ScrollToTop />
            <Seo 
                title="Contact | Coral & Hive - Natural Handwoven Rugs"
                description="Whether you know exactly what you want, or are looking for guidance in selecting or custom designing a rug, we’d love to talk to you. Please make an appointment if you’d like to visit our creative studios or loom space.We work with clients from across the globe and we ship our rugs worldwide."
                image={window.location.protocol + '//' + window.location.hostname + img}
            />
                <Layout transparent={false}>
                    <TemplateOneSection>
                        <ScrolledIntoView>
                            <ThemeHeading>
                                <h1>CONTACT</h1>
                            </ThemeHeading>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <p>Whether you know exactly what you want, or are looking for guidance in selecting or custom designing a rug, we’d love to talk to you</p>
                        </ScrolledIntoView>
                    </TemplateOneSection>
                    <TemplateImageSection img={img} img2={img} alt="Coral & Hive Team" />
                    <br /><br />
                    <section className="_section _section-form">
                        <div className="_box-2">
                            <div className="_column _column-8">
                                <ScrolledIntoView>
                                    <ContactForm 
                                        onSubmit={this.onSubmitContact} 
                                        errors={this.props.sendState.errors ? this.props.sendState.errors : null} 
                                    />
                                </ScrolledIntoView>
                            </div>
                        </div>
                    </section>
                    <section className="_section _section-template-1">
                        <div className="_box-2">
                            <div className="_column _center">
                                <ScrolledIntoView>
                                    <ThemeHeading>
                                        <h2>VISIT US</h2>
                                    </ThemeHeading>
                                </ScrolledIntoView>
                                <ScrolledIntoView>
                                    <p>Please make an appointment if you’d like to visit our creative studios or loom space.</p>
                                </ScrolledIntoView>
                            </div>
                            
                            <div className="_column">
                                <ScrolledIntoView>
                                    <h2 style={{
                                        marginTop: '60px'
                                    }}>CAPE TOWN</h2>
                                </ScrolledIntoView>
                            </div>
                            
                            <div className="_column _column-6" style={{
                                marginBottom: '30px'
                            }}>
                                <ScrolledIntoView>
                                    <h4 className="_theme-color" style={{
                                        marginTop: '0',
                                        marginBottom: '0'
                                    }}>CREATIVE STUDIO</h4>
                                    <p style={{
                                        marginTop: '0'
                                    }}>Here you can meet with our creative design team in Cape Town, who can advise on rug design and selection and show you samples of our rugs.</p>
                                    <p>
                                        <a href="https://maps.app.goo.gl/j6mhrjJjitEBt3Lz5" target="_blank" rel="noreferrer">
                                            Loft 1, The Cellars<br />
                                            Noordhoek Farm Village<br />
                                            Chapman's Peak Drive<br />
                                            Noordhoek 7985<br />
                                            Cape Town<br />
                                            South Africa
                                        </a>
                                    </p>
                                    <p>
                                        <span className="_theme-color">Contact</span><br />
                                        <span className="_theme-black">Jeannine Birch, Creative Director</span><br />
                                        <a href="mailto:m">jeannine@coralandhive.com</a><br />
                                        <a href="tel:+27212033461">+27 (0) 21 203 3461</a>
                                    </p>
                                    <p>
                                        <span className="_theme-black">Bianca Skinner, Studio Manager</span><br/>
                                        <a href="mailto:bianca@coralandhive.com">bianca@coralandhive.com</a><br />
                                        <a href="tel:+27212033461">+27 (0) 21 203 3461</a>
                                    </p>
                                    <p>
                                        <span className="_theme-black">Ashleigh-Jayne Jones, Business Development Manager </span><br/>
                                        <a href="mailto:ashleigh-jayne@coralandhive.com ">ashleigh-jayne@coralandhive.com</a><br />
                                        <a href="tel:+27212033461">+27 (0) 21 203 3461</a>
                                    </p>

                                     


                                </ScrolledIntoView>
                            </div>
                            <div className="_column _column-6" style={{
                                marginBottom: '30px'
                            }}>
                                <ScrolledIntoView>
                                    <h4 className="_theme-color" style={{
                                        marginTop: '0',
                                        marginBottom: '0'
                                    }}>LOOMS</h4>
                                    <p style={{
                                        marginTop: '0'
                                    }}>Here you can see our weavers at work, spinning, weaving and singing rugs to life on large wooden looms.</p>
                                    <p>
                                        
                                        <a href="https://goo.gl/maps/EadqnmQ8UrGiqQxz7" target="_blank" rel="noreferrer">Unit 6, 12 Stuart Close, Julian Way<br />
                                        Somerset West Business Park<br />
                                        Somerset West<br />
                                        Cape Town <br />
                                        7130 <br />
                                        South Africa</a>
                                    </p>
                                    <p>
                                        <span className="_theme-color">Contact</span><br />
                                        {/* <span className="_theme-black">Ike Bam - Loom Manager</span><br />
                                        <a href="mailto:ike@coralandhive.com">ike@coralandhive.com</a> */}
                                        <span className="_theme-black">Sebastian du Plessis, Loom Manager </span><br/>
                                        <a href="mailto:sebastian@coralandhive.com ">sebastian@coralandhive.com</a><br />
                                        <a href="tel:+27218517665">+27 (0) 21 851 7665</a>
                                    </p>
                                </ScrolledIntoView>
                            </div>
                            <div className="_column">
                                <div className="_seperator" style={{
                                    marginBottom: '60px',
                                    marginTop: '30px'
                                }} />
                            </div>
                            <div className="_column">
                                <ScrolledIntoView>
                                    <h2 style={{
                                        marginTop: '0'
                                    }}>LONDON</h2>
                                </ScrolledIntoView>
                            </div>
                            <div className="_column">
                                <ScrolledIntoView>
                                    <h4 className="_theme-color" style={{
                                        marginTop: '0',
                                        marginBottom: '0'
                                    }}>CREATIVE STUDIO</h4>
                                    <p style={{
                                        marginTop: '0'
                                    }}>Here you can meet with our creative design team in London, who can advise on rug
                                        design and selection and show you samples of our rugs.</p>
                                    <p>

                                        <a href="https://maps.app.goo.gl/5z1NukGBNtKgSLUcA" target="_blank"
                                           rel="noreferrer">G13, The Plaza, 535 Kings Road, <br/>
                                            Chelsea, SW10 0SZ</a>
                                    </p>
                                    <img src={clairBrown} alt="Claire Brown"/>
                                    <p>
                                        <span className="_theme-color">Contact</span><br/>

                                        <span className="_theme-black">Ann Johal, Studio Assistant</span><br/>
                                        <a href="mailto:ann@coralandhive.com">ann@coralandhive.com</a><br/>
                                        <a href="tel:+442033148132">+44 (0) 20 3314 8132 Ext 304</a>
                                    </p>
                                    <p>
                                        <span className="_theme-black">Claire Metcalfe, UK Sales Director</span><br/>
                                        <a href="mailto:claire@coralandhive.com">claire@coralandhive.com</a><br/>
                                        <a href="tel:+442033148132">+44 (0) 20 3314 8132 Ext 302</a>
                                    </p>
                                    <p>
                                        <span className="_theme-black">Chantal Baard, Design Studio Manager</span><br/>
                                        <a href="mailto:chantal@coralandhive.com">chantal@coralandhive.com</a><br/>
                                        <a href="tel:+442033148132">+44 (0) 20 3314 8132 Ext 303</a>
                                    </p>
                                </ScrolledIntoView>
                            </div>
                        </div>
                    </section>
                    <NewsletterSubscription className="_padding-top-none"/>
                    {/*<TemplateOneSection className="_padding-top-none" fluid>
                        <ScrolledIntoView>
                            <ThemeHeading>
                                <h1>INSTAFEED</h1>
                            </ThemeHeading>
                        </ScrolledIntoView>
                        <ScrolledIntoView>
                            <Suspense fallback={<div></div>}>
                                <FlocklerEmbed siteUuid="17e4403877706879dfc2b15e62e5d257" embedUuid="17eb4d05eae067fad8b3644e9eb2806c" />
                            </Suspense>
                        </ScrolledIntoView>
                    </TemplateOneSection>*/}
                </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        sendState: state.sendContactMailState
    }
}

export default connect(mapStateToProps, {
    sendContactEmail,
    resetSendContactEmail
})(Contact)