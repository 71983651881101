import React from 'react'
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchPublicProducts
} from '../../actions'
import CartProfileIcons from '../ui/CartProfileIcons'

class NavLinks extends React.Component {

    componentDidMount(){
        this.props.fetchPublicProducts()
        setTimeout(() => {
            const navLinks = document.querySelectorAll("._nav-link a")
            for(let i = 0; i < navLinks.length; i++){
                if(navLinks[i].nextElementSibling){
                    navLinks[i].classList.add('_dropdown-btn')
                    navLinks[i].addEventListener("click", e => {                
                        e.preventDefault()
                        navLinks[i].nextElementSibling.classList.toggle('_show-list')   
                        navLinks[i].classList.toggle('_up');                
                    })
                } else{
                    navLinks[i].addEventListener("click", () => this.props.onClick())
                }
            }  
        }, 1000);
              
    }

    scrollToPosition = () => {
        window.scrollTo({
            top: 0, // scroll so that the element is at the top of the view
            // behavior: 'smooth' // smooth scroll
        })   
    }

    renderProductLinks = () => {
        return this.props.products.map(({title, slug}, index) => {
            return (
                <li className="_nav-link" key={index}>
                    <NavLink to={`/rugs/${slug}`} activeClassName="_active" onClick={() => this.scrollToPosition()}>{title}</NavLink>
                </li>
            )
        })
    }

    render(){
        return(
            <ul className="_nav-links">
                <li className="_nav-link">
                    <NavLink exact to="/" activeClassName="_active">Home</NavLink>
                </li>
                <li className="_nav-link">
                    <NavLink to="/about" activeClassName="_active">About</NavLink>
                </li>
                <li className="_nav-link">
                    <NavLink to="/rugs" activeClassName="_active">Rugs</NavLink>
                    <ul>
                        {this.renderProductLinks()}
                    </ul>
                </li>
                <li className="_nav-link">
                    <NavLink to="/shop/cushions" activeClassName="_active">Cushions & Throws</NavLink>
                </li>
                <li className="_nav-link">
                    <NavLink to="/colours-and-patterns" activeClassName="_active">Colours & Patterns</NavLink>
                </li>
                <li className="_nav-link">
                    <NavLink to="/studio-samples" activeClassName="_active">Studio Samples</NavLink>
                </li>
                <li className="_nav-link">
                    <NavLink to="/blog" activeClassName="_active">Blog</NavLink>
                </li>
                <li className="_nav-link">
                    <NavLink to="/gallery" activeClassName="_active">Gallery</NavLink>
                </li>
                <li className="_nav-link">
                    <NavLink to="/contact" activeClassName="_active">Contact</NavLink>
                </li>
                <li className="_nav-link">
                    <NavLink to="/shop" activeClassName="_active">Shop</NavLink>
                </li>
                {/* <li className="_nav-link">
                    <NavLink to="/cart" activeClassName="_active">My Cart</NavLink>
                </li>
                <li className="_nav-link">
                    <NavLink to="/my-account" activeClassName="_active">My Account</NavLink>
                </li> */}
                <br />
                <CartProfileIcons />
            </ul>
        )
    }
}

const mapStateToProps = state => {
    return {
        products: Object.values(state.publicProductsState)
    }
}

export default connect(mapStateToProps, {
    fetchPublicProducts
})(NavLinks)